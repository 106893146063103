import Modal from "react-bootstrap/Modal";
import { useState, useMemo, useEffect } from "react";
import EyeOffOutline from "mdi-react/EyeOffOutlineIcon";
import EyeOutline from "mdi-react/EyeOutlineIcon";
import { useMutation, useQueries, useQuery } from "react-query";
import { toast } from "react-toastify";
import Select from "react-select";

import { useAuth } from "../../hooks/useAuth";
import { sendFormData, fetchActionsUtil } from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import Avatar from "../utils/Avatar";
import { appSettings, backendApis } from "../../config";
import CloseIcon from "mdi-react/CloseIcon";
import { isEmpty, uniq, uniqBy } from "lodash";
import { queryActions } from "../../utils/reactQueryActions";
const apis = [backendApis];

const allDepartment = [
  "",
  "Admin",
  "Accountant",
  "Cashier",
  "Content Management",
  "Contracts",
  "HR",
  "Sales",
  "Waiter",
  // "Shout Out",
  "Store",
  "Warehouse",
  "Production",
  "Sales Manager",
  "Operations",
  "Business Development",
  "Procurement",
  "QHSE",
  "Maintenance",
  "Document Control",
  "Government InvexERP",
  "Supply and Logistics",
  "Hospital Management Board",
  "Primary Healthcare Center",
  "Ministry of Health",
];

const alLevels = ["", "High", "Low"];

const branches = ["", "HQ", "Small Branch"];

const userType = ["core", "passive"];

const initials = {
  profilePics: "",
  Name: "",
  password: "",
  confirmpassword: "",
  AccessLavel: "",
  Warehouse: [],
  username: "",
  Department: "",
  email: "",
  Branch: "",
  Staff_ID: "",
  Title: "",
  userType: "core",
};

const CreateUserAccount = (props) => {
  const { backendUrl, brachData, token } = useAuth();

  const [inputData, setInputData] = useState(initials);
  const [showText, setShowText] = useState({
    password: false,
    confirmpassword: false,
  });

  const fetchcompanyDetails = async (url) => {
    const res = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    });

    const data = await res.json();

    let companyData = data?.company;

    return companyData;
  };

  const company = useQuery(
    ["GET_COMPANY_DETAILS"],
    () => fetchcompanyDetails(`${backendUrl}/api/company/details`),
    {}
  );

  const fetchAllBranchStaff = async (endpoint) => {
    try {
      const res = await fetch(endpoint);

      const data = await res.json();

      return { ...data, url: endpoint };
    } catch (error) {
      throw new Error(`Failed to fetch ${endpoint}`);
    }
  };

  const queries = useQueries(
    apis
      ?.map((company) =>
        company.map((api) => ({
          queryKey: ["user", api.name],
          queryFn: () => fetchAllBranchStaff(`${api.url}/api/users`),
        }))
      )
      .flat()
  );

  // const staffUserDetails = useMemo(() => {
  //   if (
  //     queries &&
  //     queries.length > 0 &&
  //     Boolean(queries[0]) &&
  //     process.env.REACT_APP_SITE_TITLE === "InvexERP"
  //   ) {
  //     const companies = queries?.map((query) => query.data);

  //     const staff = companies;

  //     const companyStaff = staff
  //       ?.map((user) => {
  //         return user?.staff?.map((staf) => {
  //           return { ...staf, url: user?.url };
  //         });
  //       })
  //       .flat();

  //     const uniqStaff = uniqBy(companyStaff, "username");

  //     function findUserBranches(username) {
  //       return companyStaff.filter((url) => url?.username === username);
  //     }

  //     const userBranches = uniqStaff.map((branch) => {
  //       const found = findUserBranches(branch?.username);

  //       const urls = found.map((url) => url?.url);
  //       return { ...branch, url: urls };
  //     });

  //     let uniqueSTaffWithStaffType = userBranches?.map((user) => ({
  //       ...user,
  //       userType: user?.userType ? user?.userType : "core",
  //     }));

  //     uniqueSTaffWithStaffType = uniqueSTaffWithStaffType.filter(
  //       (staff) => staff?.username?.toLowerCase() !== "support"
  //     );

  //     const totalCompanyCoreUsers =
  //       company?.data?.coreUsers || company?.data?.Num_Users;
  //     const totalCompanyPassiveUsers = company?.data?.passiveUsers || 0;

  //     const currentCoreUsers = uniqueSTaffWithStaffType.filter(
  //       (user) => user?.userType === "core"
  //     ).length;

  //     const currentPassiveUsers = uniqueSTaffWithStaffType.filter(
  //       (user) => user?.userType === "passive"
  //     ).length;

  //     return {
  //       staff: uniqueSTaffWithStaffType,
  //       activatedCoreUsers: currentCoreUsers,
  //       activatedPassiveUsers: currentPassiveUsers,
  //       totalCoreUsers: totalCompanyCoreUsers,
  //       totalPassiveUsers: totalCompanyPassiveUsers,
  //     };
  //   } else {
  //     return {};
  //   }
  // }, [queries, company?.data]);

  const staffUserDetails = useMemo(() => {
    try {
      let userBranches = [];
      if (
        queries &&
        queries.length > 0 &&
        Boolean(queries[0])
        //&&
        //  process.env.REACT_APP_SITE_TITLE === "InvexERP"
      ) {
        const companies = queries?.filter((query) => query.data);

        const staff = companies;

        const companyStaff = staff
          ?.map((user) => {
            const url = user?.data?.url;
            return user?.data?.staff?.map((staf) => {
              return { ...staf, url: url };
            });
          })
          .flat();

        let uniqStaff = uniqBy(companyStaff, "username");

        function findStaffBranch(username) {
          let findStaffBranch = companyStaff?.filter(
            (staff) => staff.username === username
          );

          findStaffBranch = findStaffBranch?.map((branch) => ({
            branch: branch.Branch,
            url: branch.url,
          }));
          return findStaffBranch;
        }

        uniqStaff = uniqStaff.map((staff) => {
          const staffBranchFound = findStaffBranch(staff?.username);
          return { ...staff, Branch: staffBranchFound };
        });

        // uniqStaff = uniqStaff.filter(
        //   (branch) => branch?.Staff_ID === props?.userId
        // );

        console.log("this is the compnay uuser type ==== ", company?.data);

        function findUserBranches(username) {
          return companyStaff?.filter((url) => url?.username === username);
        }

        userBranches = uniqStaff.map((branch) => {
          const found = findUserBranches(branch?.username);

          const urls = found.map((url) => url?.url);
          return { ...branch, url: urls };
        });
      }

      const remainingOtherBranchUsers = userBranches?.filter(
        (staff) => staff?.username?.toLowerCase() !== "support"
      ).length;

      if (process.env.REACT_APP_SITE_TITLE === "InvexERP") {
        let uniqueSTaffWithStaffType = userBranches?.map((user) => ({
          ...user,
          userType: user?.userType ? user?.userType : "core",
        }));

        uniqueSTaffWithStaffType = uniqueSTaffWithStaffType?.filter(
          (staff) => staff?.username?.toLowerCase() !== "support"
        );

        const totalCompanyCoreUsers =
          company?.data?.coreUsers || company?.data?.Num_Users;
        const totalCompanyPassiveUsers = company?.data?.passiveUsers || 0;

        const currentCoreUsers = uniqueSTaffWithStaffType?.filter(
          (user) => user?.userType === "core"
        ).length;

        const currentPassiveUsers = uniqueSTaffWithStaffType?.filter(
          (user) => user?.userType === "passive"
        ).length;

        return {
          staff: uniqueSTaffWithStaffType,
          activatedCoreUsers: currentCoreUsers,
          activatedPassiveUsers: currentPassiveUsers,
          totalCoreUsers: totalCompanyCoreUsers,
          totalPassiveUsers: totalCompanyPassiveUsers,
          otherBranchTotalStaff: remainingOtherBranchUsers,
          totalUsers: company?.data?.Num_Users,
        };
      } else {
        let uniqueSTaffWithStaffType = userBranches?.filter(
          (staff) => staff?.username?.toLowerCase() !== "support"
        );

        return {
          staff: uniqueSTaffWithStaffType,
          totalUsers: company?.data?.Num_Users,
          otherBranchTotalStaff: remainingOtherBranchUsers,
        };
      }
    } catch (err) {
      // console.log(err);
    }
  }, [queries, company?.data]);

  console.log(" here is the all users  ================= ", staffUserDetails);

  const { data, isFetching } = useQuery(
    ["FETCH_WAREHOUSE"],
    () => fetchActionsUtil(`${backendUrl}/api/warehouse`, "GET"),
    {
      keepPreviousData: true,
    }
  );

  const mergedWarehouse = useMemo(() => {
    return data?.warehouses?.map((el) => ({
      label: el.W_name,
      value: el.W_ID,
    }));
  }, [data]);

  const OnChangeInput = (e) => {
    const { name, value, files } = e.target;
    if (name === "profilePics")
      return setInputData({ ...inputData, [name]: files[0] });

    setInputData({ ...inputData, [name]: value });
  };

  const saveAccount = useMutation(
    (payload) => sendFormData(`${inputData.Branch[0]?.url}/api/users`, payload),
    {
      onSuccess: (data) => {
        // console.log(data);
        props.refetch();
        // props.setShowEditUserPriviledgesModal()
        toast.success("Account saved Successfully");
        setInputData(initials);
        props.onHide();
      },
      onError: ({ message = "" }) => {
        toast.error(message);
      },
    }
  );

  function checkDepartment() {
    const canAccessDepartment = [
      { department: "Admin" },
      { department: "Accountant" },
      { department: "Content Management" },

      { department: "HR" },
      { department: "Warehouse" },
      { department: "Production" },
      { department: "Operations" },
      { department: "Business Development" },
      { department: "Procurement" },
      { department: "Document Control" },
    ].find((dp) => dp?.department === inputData?.Department);

    if (!isEmpty(canAccessDepartment)) {
      return true;
    } else {
      return false;
    }
  }
  const saveData = async (e) => {
    e.preventDefault();

    const { profilePics, confirmpassword, Branch, ...rest } = inputData;

    if (!rest.Staff_ID) return toast.error("Empl ID can not be empty");
    if (!confirmpassword || !inputData.password)
      return toast.error("Enter Pasword and Confirm Password");
    if (confirmpassword !== inputData.password)
      return toast.error("MisMatch Password");

    rest.brachData = JSON.stringify(brachData);
    rest.Warehouse = rest?.Warehouse?.map((d) => d?.value);
    if (!Branch || Branch.length < 1) return toast.error("Add branches");

    const formData = new FormData();
    for (const [key, value] of Object.entries(rest)) {
      formData.append(key, value);
    }

    formData.append("Branch", JSON.stringify(Branch));

    if (profilePics) {
      formData.append(
        "profilePics",
        profilePics,
        `${rest.Staff_ID}_profilePics`
      );
    }

    if (
      process.env.REACT_APP_SITE_TITLE === "InvexERP" &&
      inputData?.username.toLocaleLowerCase() !== "support"
    ) {
      if (inputData?.userType === "passive" && checkDepartment()) {
        toast.error("Passive User can not be created with this department");
        return;
      }
      // if (inputData?.userType === "core" && !checkDepartment()) {
      //   toast.error("Core User can not be created with this department");
      //   return;
      // }

      if (
        inputData.userType === "core" &&
        Number(staffUserDetails?.activatedCoreUsers) >=
        Number(staffUserDetails?.totalCoreUsers)
      ) {
        console.log(
          "this is the activated core users  ================= ",
          staffUserDetails?.activatedCoreUsers
        );
        console.log(
          "this is the total  core users  ================= ",
          staffUserDetails?.totalCoreUsers
        );

        toast.error("Core users limit exceeded");
        return;
      }
      if (
        inputData.userType === "passive" &&
        Number(staffUserDetails?.activatedPassiveUsers) >=
        Number(staffUserDetails?.totalPassiveUsers)
      ) {
        console.log(
          "this is the activated PASSIVE users  ================= ",
          staffUserDetails?.activatedPassiveUsers
        );
        console.log(
          "this is the total  PASSIVE users  ================= ",
          staffUserDetails?.totalPassiveUsers
        );

        toast.error("Passive users limit exceeded");
        return;
      }
    } else {
      if (
        Number(staffUserDetails?.otherBranchTotalStaff) >
        Number(staffUserDetails?.totalUsers)
      ) {
        toast.error("Users limit exceeded");
        return;
      }
    }

    if (
      await ConfirmDialog({
        title: "Save Account",
        description: "Are you sure you want to save this Account",
      })
    ) {
      saveAccount.mutate(formData);
    }
  };

  // useEffect(() => {
  //   if (
  //     inputData.Department === "Accountant" ||
  //     inputData.Department === "Admin" ||
  //     inputData.Department === "Procurement" ||
  //     inputData.Department === "HR" ||
  //     inputData.Department === "Warehouse" ||
  //     inputData.Department === "Production" ||
  //     inputData.Department === "Content Management" ||
  //     inputData.Department === ""
  //   ) {
  //     setInputData({ ...inputData, userType: "core" });
  //   } else {
  //     setInputData({ ...inputData, userType: "passive" });
  //   }
  // }, [inputData]);

  const getDepartments = async () => {
    let response = await fetch(`${backendUrl}/api/users/departments`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.departments = uniq([
      ...allDepartment,
      ...data.departments.map((el) => el.Department),
    ]);
    return data;
  };
  const { data: { departments } = { departments: [] } } = useQuery(
    [queryActions.GET_DEPARTMENTS],
    () => getDepartments(),
    {}
  );

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div>
          <div>
            <div className="d-flex justify-content-between">
              <h6>Create User</h6>
              <h5 role="button" className="text-lg" onClick={props.onHide}>
                <CloseIcon />
              </h5>
            </div>
            <p className="text-muted">
              Add new user by filling in the following forms.
            </p>
          </div>
          <div className="row mt-4">
            <div className="">
              <div>
                <form onSubmit={saveData}>
                  <div className="row">
                    <div className="col-2 p-3 mt-4">
                      <Avatar
                        onFileUpload={OnChangeInput}
                        image={inputData.profilePics}
                        name={inputData.Name}
                        nameInput="profilePics"
                        style={{ width: "8rem", height: "8rem" }}
                        userId="E"
                      />
                    </div>
                    <div className="col-10 p-3">
                      <div className="row">
                        <div className="col-6">
                          <div className="mb-3 row">
                            <label className="col-form-label fw-bold">
                              Empl ID
                            </label>
                            <div>
                              <input
                                type="text"
                                className="form-control"
                                value={inputData.Staff_ID}
                                name="Staff_ID"
                                onChange={OnChangeInput}
                              />
                            </div>
                          </div>
                          {/*  */}
                          <div className="mb-3 row">
                            <label className="col-form-label fw-bold">
                              Full Name
                            </label>
                            <div>
                              <input
                                type="text"
                                className="form-control"
                                value={inputData.Name}
                                name="Name"
                                onChange={OnChangeInput}
                              />
                            </div>
                          </div>
                          {/*  */}
                          <div className="mb-3 row">
                            <label className="col-form-label fw-bold">
                              Password
                            </label>
                            <div>
                              <div className="input-group">
                                <input
                                  type={showText.password ? "text" : "password"}
                                  className="form-control"
                                  value={inputData.password}
                                  name="password"
                                  onChange={OnChangeInput}
                                  placeholder="********"
                                />
                                {showText.password ? (
                                  <span
                                    onClick={() =>
                                      setShowText({
                                        ...showText,
                                        password: false,
                                      })
                                    }
                                    className="input-group-text text-xs bg-white"
                                  >
                                    <EyeOutline />
                                  </span>
                                ) : (
                                  <span
                                    onClick={() =>
                                      setShowText({
                                        ...showText,
                                        password: true,
                                      })
                                    }
                                    className="input-group-text text-xs bg-white"
                                  >
                                    <EyeOffOutline />
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          {/*  */}
                          <div className="mb-3 row">
                            <label className="col-form-label fw-bold">
                              Comfirm Password
                            </label>
                            <div>
                              <div className="input-group">
                                <input
                                  type={
                                    showText.confirmpassword
                                      ? "text"
                                      : "password"
                                  }
                                  className="form-control"
                                  value={inputData.confirmpassword}
                                  name="confirmpassword"
                                  onChange={OnChangeInput}
                                  placeholder="********"
                                />

                                {showText.confirmpassword ? (
                                  <span
                                    onClick={() =>
                                      setShowText({
                                        ...showText,
                                        confirmpassword: false,
                                      })
                                    }
                                    className="input-group-text text-xs bg-white"
                                  >
                                    <EyeOutline />
                                  </span>
                                ) : (
                                  <span
                                    onClick={() =>
                                      setShowText({
                                        ...showText,
                                        confirmpassword: true,
                                      })
                                    }
                                    className="input-group-text text-xs bg-white"
                                  >
                                    <EyeOffOutline />
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          {/*  */}
                          <div className="mb-3 row">
                            <label className="col-form-label fw-bold">
                              Access Level
                            </label>
                            <div>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                value={inputData.AccessLavel}
                                name="AccessLavel"
                                onChange={OnChangeInput}
                              >
                                {alLevels.map((a, i) => (
                                  <option key={i} value={a}>
                                    {a}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          {process.env.REACT_APP_SITE_TITLE === "InvexERP" && (
                            <div className="mb-3 row">
                              <label className="col-form-label fw-bold">
                                User Type
                              </label>
                              <div>
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  value={inputData.userType}
                                  name="userType"
                                  onChange={OnChangeInput}
                                >
                                  {userType.map((a, i) => (
                                    <option key={i} value={a}>
                                      {a}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          )}

                          {/*  */}
                        </div>
                        {/* Second side inputs */}
                        <div className="col-6">
                          <div className="mb-3">
                            <label className="col-sm-4 col-form-label fw-bold">
                              Title
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              value={inputData.Title}
                              name="Title"
                              onChange={OnChangeInput}
                            />
                          </div>
                          {/*  */}
                          <div className="mb-3 row">
                            <label className="col-sm-4 col-form-label fw-bold text-nowrap">
                              User Name
                            </label>
                            <div>
                              <input
                                type="text"
                                className="form-control"
                                value={inputData.username}
                                name="username"
                                onChange={OnChangeInput}
                              />
                            </div>
                          </div>
                          {/*  */}
                          <div className="mb-3 row">
                            <label className="col-sm-4 col-form-label fw-bold">
                              Department
                            </label>
                            <div>
                              <Select
                                classNamePrefix="form-select"
                                menuPosition="fixed"
                                menuPlacement="auto"
                                placeholder="All"
                                isSearchable={true}
                                onChange={(selected) => {
                                  setInputData({
                                    ...inputData,
                                    Department: selected?.value,
                                  });
                                }}
                                defaultValue={{
                                  value: "",
                                  label: "Select Department",
                                }}
                                options={
                                  departments
                                    .filter((department) =>
                                      !appSettings.isMedbury &&
                                        [
                                          "Hospital Management Board",
                                          "Primary Healthcare Center",
                                          "Ministry of Health",
                                        ].includes(department)
                                        ? false
                                        : true
                                    )
                                    .map((d, i) => ({
                                      label: d,
                                      value: d,
                                    })) || []
                                }
                                isClearable
                              />

                              {/* <select
                                className="form-select"
                                aria-label="Default select example"
                                value={inputData.Department}
                                name="Department"
                                onChange={OnChangeInput}
                              >
                                {allDepartment
                                  .filter((department) =>
                                    !appSettings.isMedbury &&
                                    [
                                      "Hospital Management Board",
                                      "Primary Healthcare Center",
                                      "Ministry of Health",
                                    ].includes(department)
                                      ? false
                                      : true
                                  )
                                  .map((d, i) => (
                                    <option key={i} value={d}>
                                      {d}
                                    </option>
                                  ))}
                              </select> */}
                            </div>
                          </div>
                          {/*  */}
                          {inputData.Department === "Warehouse" && (
                            <div className="mb-3 row">
                              <label className="col-sm-4 col-form-label fw-bold text-nowrap">
                                Choose Warehouse
                              </label>
                              <div>
                                <Select
                                  closeMenuOnSelect={true}
                                  isLoading={isFetching}
                                  isMulti
                                  isSearchable={true}
                                  onChange={(select) => {
                                    setInputData({
                                      ...inputData,
                                      Warehouse: select,
                                    });
                                  }}
                                  options={mergedWarehouse}
                                />
                              </div>
                            </div>
                          )}
                          {/*  */}
                          <div className="mb-3 row">
                            <label className="col-sm-4 col-form-label fw-bold">
                              Email
                            </label>
                            <div>
                              <input
                                type="text"
                                className="form-control"
                                value={inputData.email}
                                name="email"
                                onChange={OnChangeInput}
                              />
                            </div>
                          </div>
                          {/*  */}
                          <div className="mb-3 row">
                            <label className="col-sm-4 col-form-label fw-bold">
                              Branch
                            </label>
                            <div>
                              <Select
                                menuPosition="fixed"
                                className="mx-3"
                                menuPlacement="auto"
                                placeholder="All"
                                name="Branch"
                                onChange={(selected) => {
                                  setInputData({
                                    ...inputData,
                                    Branch: selected,
                                  });
                                }}
                                isSearchable={true}
                                isMulti
                                options={backendApis?.map((api) => ({
                                  ...api,
                                  value: api.name,
                                  label: api.name,
                                }))}
                              />
                              {/* <select
                                className="form-select"
                                aria-label="Default select example"
                                value={inputData.Branch}
                                name="Branch"
                                onChange={OnChangeInput}
                              >
                                {branches.map((d, i) => (
                                  <option key={i} value={d}>
                                    {d}
                                  </option>
                                ))}
                              </select> */}
                            </div>
                          </div>

                          {/*  */}
                        </div>
                      </div>
                      {/*  */}

                      <div className="mt-4 d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg"
                          disabled={saveAccount.isLoading}
                        >
                          {saveAccount.isLoading
                            ? "Please wait..."
                            : "Create User"}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateUserAccount;
