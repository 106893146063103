import { useState, useRef, useEffect, useMemo } from "react";
import { NavLink } from "react-router-dom";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import { useResolveRootRoles } from "../../utils/hooks";

export function InventoryNav(props) {
  const { getRootRoles } = useResolveRootRoles();
  const items = useMemo(() => {
    const navItems = [
      {
        name: "Inventory Entry",
        to: "inventory-entry",
      },
      {
        name: "Inventory on Transit",
        to: "/receive-inventory",
      },
      {
        name: "Item Issuer",
        to: "item-issuer",
      },
      {
        name: "Inbound/Outbound Inventory",
        to: "inbound-outbound-inventory",
      },
      {
        name: "Stock Count",
        to: "stock-counts",
      },
      {
        name: "Item Category",
        to: "category",
      },
      {
        name: "Item Manufacturer",
        to: "manufactuer",
      },
      {
        name: "Inventory by Serial Number",
        to: "item-serial-details",
      },
      {
        name: "Serial Numbers",
        to: "serial-numbers",
      },
      {
        name: "Vendor",
        to: "vendor",
      },
      {
        name: "Manage Transaction",
        to: "manage-transaction",
      },
      {
        name: "Item Manager",
        to: "inventory-manager",
      },
      {
        name: "Item Conversion",
        to: "item-conversion",
      },
      {
        name: "Processing",
        to: "processing-manager",
      },
      {
        name: "Items Below Reorder Level",
        to: "items-out-of-stock",
      },
      {
        name: "Items Expiry Report",
        to: "items-expiry-report",
      },
      {
        name: "Items Run Rate",
        to: "items-run-rate",
      },
      {
        name: "Expected Items Report",
        to: "items-expected-report",
      },
      {
        name: "Internal Stock Received",
        to: "internal-stock-received",
      },
      {
        name: "Shipping Cost",
        to: "shopping-cost",
      },
      {
        name: "Procurement Plan",
        to: "items-forcast-recorder",
      },
      {
        name: "Run Rate",
        to: "items-run-rate",
      },
      {
        name: "Storage Location",
        to: "storage-location",
      },
    ];

    return getRootRoles({
      parent: "Sales Inventory",
      navItems,
    });
  });

  const fileHolder = useRef(null);
  const [isShowLeftSroll, setShowLeftScroll] = useState(false);
  const [isShowRightSroll, setShowRightScroll] = useState(false);

  const showLeftSroll = () => {
    setShowLeftScroll(fileHolder?.current && fileHolder.current.scrollLeft > 0);
  };

  const showRightScroll = () => {
    setShowRightScroll(
      fileHolder.current &&
      fileHolder.current.offsetWidth + fileHolder.current.scrollLeft <
      fileHolder.current.scrollWidth
    );
  };

  const srcollFileHolder = () => {
    showLeftSroll();
    showRightScroll();
  };

  useEffect(() => {
    setTimeout(() => {
      if (fileHolder.current) srcollFileHolder();
    }, 50);
  }, [fileHolder]);

  return (
    <div className=" tabs-holder">
      {isShowLeftSroll && (
        <button
          className="btn slider-btn left"
          onClick={() => (fileHolder.current.scrollLeft -= 500)}
        >
          <ChevronLeftIcon />
        </button>
      )}
      <div
        ref={fileHolder}
        onScroll={(e) => srcollFileHolder(e)}
        className="dashboard-tabs innerpage-tabs px-4"
      >
        <ul className="nav nav-tabs">
          {items.map((item, index) => (
            <li key={index} className="nav-item">
              <NavLink className={`nav-link ga-nav-tab`} to={item.to}>
                {item.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>{" "}
      {isShowRightSroll && (
        <button
          className="btn slider-btn right"
          onClick={() => (fileHolder.current.scrollLeft += 500)}
        >
          <ChevronRightIcon />
        </button>
      )}
    </div>
  );
}
