import { useFormik } from "formik";
import { Button, Form } from "react-bootstrap";
import * as yup from "yup"
import { useAuth } from "../hooks/useAuth";
import { useMutation } from "react-query";
import { fetchActionsUtil } from "../utils/helpers";
import { toast } from "react-toastify";

export default function ReceiptDialogAddon({
  TransactionID
}) {

  const { backendUrl } = useAuth();

  const saveCategory = useMutation(
    (payload) =>
      fetchActionsUtil(`${backendUrl}/api/invoice/send-email`, "POST", "", payload),
    {
      onSuccess: () => {
        toast.success("Sent");
      },
      onError: ({ message }) => {
        toast.error('Error in sending');
      },
    }
  );


  const formik = useFormik({
    initialValues: {
      sendRecieptEmail: true,
      sendContractEmail: true,
      TransactionID
    },
    validationSchema: yup.object().shape({

    }),
    onSubmit: (values) => {
      saveCategory.mutate(values)
    }
  })


  return <div className="border rounded p-3">
    <p className="fw-6">Send Email</p>
    <Form
      noValidate
      onSubmit={formik.handleSubmit}
      autoComplete="off"
    >
      <div className="my-4">
        <Form.Check
          inline
          label="Receipt"
          name="sendRecieptEmail"
          type={"checkbox"}
          checked={formik.values.sendRecieptEmail}
          onChange={formik.handleChange}
        />
        <Form.Check
          inline
          label="Contract"
          name="sendContractEmail"
          type={"checkbox"}
          checked={formik.values.sendContractEmail}
          onChange={formik.handleChange}
        />
      </div>
      <Button type="submit" disabled={saveCategory?.isLoading}>{saveCategory?.isLoading ? 'Please wait...' : 'Send'}</Button>
    </Form>
  </div>
}
